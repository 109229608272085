/* src/styles/AdminCommon.css */
body{
    background-color: #1a1a1a !important;
}
.admin-page {
    background-color: var(--admin-bg);
    color: var(--admin-text);
  }
  
  .admin-section {
    background-color: var(--admin-card-bg);
    border-radius: 8px;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
  
  .admin-form-control {
    background-color: var(--admin-input-bg);
    border-color: var(--admin-input-border);
    color: var(--admin-text);
  }
  
  .admin-form-control:focus {
    background-color: var(--admin-hover-bg);
    border-color: var(--admin-input-border);
    color: var(--admin-text);
    box-shadow: none;
  }
  
  .admin-card {
    background-color: var(--admin-card-bg);
    border: none;
    border-radius: 8px;
  }
  
  .admin-btn {
    border-color: var(--admin-border);
    color: var(--admin-text);
  }
  
  .admin-btn:hover {
    background-color: var(--admin-hover-bg);
    border-color: var(--admin-border);
    color: var(--admin-text);
  }
  .admin-layout {
    display: flex;
    min-height: 100vh;
    outline: 3px solid red;
  }
  
  .admin-content {
    flex-grow: 2;
    margin-left: 1rem;
    padding: 20px;
    background-color: #2a2a2a;
  }
/* src/components/LandingPage/LandingPage.css */
body{
  background-color: #000 ;
}
.landing-container {
    height: 100vh;
    background-color: #000;

    overflow: visible;
  }
  
  .text-column {
    padding: 4rem;
    color: #fff;
    height: 100vh;
  }
  
  .photo-column {
    height: 100vh;
    padding: 0;
    position: relative;
    overflow: hidden !important;
  }
  
  .photo-grid-container {
    height: 90vh;
    overflow-y: auto;
    padding: 4px;
    overflow: visible !important;
  }
  
  /* Masonry styles */
  .masonry-grid {
    display: flex;
    margin-left: -4px; /* Kompensacja paddingu kolumn */
    width: auto;
    z-index: 1;
  }
  
  .masonry-grid_column {
    padding-left: 4px; /* Małe odstępy */
    background-clip: padding-box;
  }
  
  /* Różne rozmiary elementów */
  .masonry-item {
    margin-bottom: 4px;
    position: relative;
    overflow: hidden;
    border-radius: 2px;
    background: #1a1a1a;
  }
  
  .masonry-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.3s ease;
  }
  
  .masonry-item:hover img {
    transform: scale(1.05);
  }
  
  /* Rozmiary elementów */
  .masonry-item.small {
    height: 200px;
  }
  
  .masonry-item.medium {
    height: 300px;
  }
  
  .masonry-item.tall {
    height: 400px;
  }
  
  .masonry-item.large {
    height: 350px;
  }
  
  .masonry-item.wide {
    height: 250px;
  }
  
  /* Stylizacja scrollbara */
  .photo-grid-container::-webkit-scrollbar {
    width: 6px;
  }
  
  .photo-grid-container::-webkit-scrollbar-track {
    background: #1a1a1a;
  }
  
  .photo-grid-container::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 3px;
  }
  
  .photo-grid-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Buttons */
  .btn-outline-dark {
    border-width: 2px;
    border-color: #fff;
    color: #fff;
  }
  
  .btn-outline-dark:hover {
    background-color: #fff;
    color: #000;
  }
  
  .btn-dark {
    background-color: #fff;
    color: #000;
    border: 2px solid #fff;
  }
  
  .btn-dark:hover {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
  }
  
  /* Responsywność */
  @media (max-width: 1400px) {
    .masonry-item.small { height: 180px; }
    .masonry-item.medium { height: 260px; }
    .masonry-item.tall { height: 350px; }
    .masonry-item.large { height: 300px; }
    .masonry-item.wide { height: 220px; }
  }
  
  @media (max-width: 992px) {
    .masonry-item.small { height: 160px; }
    .masonry-item.medium { height: 240px; }
    .masonry-item.tall { height: 320px; }
    .masonry-item.large { height: 280px; }
    .masonry-item.wide { height: 200px; }
  }
  #phone-view-landing{
    position: relative;
  }
  .phone-view-box{
    position: absolute;
    display: none;
  }
  @media (max-width: 768px) {
    .text-column {
      padding: 2rem;
      height: auto;
    }
  
    .photo-column {
      height: auto;
      
    }
    .masonry-grid{
      opacity: 0.2;
    }
    .phone-view-box{
      position: absolute;
      display: block;
      top: 40%;
      z-index: 1;
      color: whitesmoke;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .masonry-item.small,
    .masonry-item.medium,
    .masonry-item.tall,
    .masonry-item.large,
    .masonry-item.wide {
      height: 200px;
    }
  }
  /* skelton */
  .photo-skeleton {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 4px;
    height: 90vh;
    padding: 4px;
  }
  
  .skeleton-item {
    background: linear-gradient(
      110deg,
      #1a1a1a 30%,
      #252525 50%,
      #1a1a1a 70%
    );
    background-size: 200% 100%;
    border-radius: 2px;
    height: 250px;
    animation: shimmer 1.5s infinite linear;
  }
  
  .section-skeleton {
    height: 400px;
    background: linear-gradient(
      110deg,
      #1a1a1a 30%,
      #252525 50%,
      #1a1a1a 70%
    );
    background-size: 200% 100%;
    margin: 20px 0;
    border-radius: 2px;
    animation: shimmer 1.5s infinite linear;
  }
  
  /* Animacje */
  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  
  /* Płynne pojawianie się zdjęć */
.masonry-item {
  opacity: 0;
  transform: translateY(20px) scale(0.95);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;
}

.masonry-item.visible {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.masonry-item img {
  opacity: 0;
  filter: blur(10px);
  transition: opacity 0.4s ease-out, filter 0.4s ease-out;
  will-change: opacity, filter;
}

.masonry-item img.loaded {
  opacity: 1;
  filter: blur(0);
}

/* Zachowaj pozostałe efekty hover */
.masonry-item:hover img {
  transform: scale(1.05);
}

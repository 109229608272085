.sidebar-1 {
  width: 256px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #1a1a1a !important;
  padding-top: 1rem;
}

.sidebar-link {
  color: #a0a0a0 !important;
  padding: 0.75rem 1.25rem !important;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.sidebar-link:hover {
  color: #fff !important;
  background-color: rgba(255,255,255,0.1);
}

.sidebar-link.active {
  color: #fff !important;
  background-color: rgba(255,255,255,0.1);
}

.chevron {
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

.lists-header {
  padding: 1rem 1.25rem 0.5rem;
  color: #666;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.status-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
}

.status-dot.green {
  background-color: #4caf50;
}

.status-dot.orange {
  background-color: #ff9800;
}

.dots {
  color: #666;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 0;
}

@keyframes floatComplex {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-8px) rotate(2deg);
  }
  75% {
    transform: translateY(8px) rotate(-2deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

.img-fluid-logo {
  animation: floatComplex 6s ease-in-out infinite;
  filter: drop-shadow(0 5px 15px rgba(0,0,0,0.3));
  margin-left: -1rem;
}